import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const HorizontalSpacer = ({ config, isAdmin }) => {
  const { spacing } = config;

  return (
    <div
      className={cx('w-full block', {
        '[background:linear-gradient(90deg,#f6f6f6_9px,transparent_1%)_50%,linear-gradient(#f6f6f6_9px,transparent_1%)_50%,#0a545f] [background-size:10px_10px] border border-solid border-gray-light4': isAdmin,
      })}
      style={{ height: `${spacing.content}px` }}
    />
  );
};

const HorizontalSpacerUI = props => {
  if (props?.config?.elementAttribute?.id) {
    return (
      <div id={props?.config?.elementAttribute?.id}>
        <HorizontalSpacer {...props} />
      </div>
    );
  }
  return <HorizontalSpacer {...props} />;
};

HorizontalSpacer.propTypes = {
  config: PropTypes.shape({
    spacing: PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  isAdmin: PropTypes.bool,
};

HorizontalSpacerUI.propTypes = {
  config: PropTypes.shape({
    elementAttribute: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default HorizontalSpacerUI;
